import React, { forwardRef, useState } from 'react';
import { Button, useTracking } from '$shared/components';
import { Link } from '~/lib/data-contract';
import NextLink from 'next/link';
import { SidepanelModule } from './components/SidePanelModule';

export type CallToActionProps = {
    callToAction: Link;
    onClick?: (
        e?:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    ) => void;
    itemHeadline?: string;
    prefetch?: boolean;
};

export const CallToAction = forwardRef<HTMLAnchorElement, CallToActionProps>((props, ref) => {
    const { trackLink } = useTracking();
    const { callToAction, onClick, itemHeadline, prefetch } = props;

    const [showSidePanel, setShowSidePanel] = useState(false);

    const handleCloseSidePanel = () => {
        setShowSidePanel(false);
    };

    if (!callToAction.title) {
        return <></>;
    }

    if (callToAction.url) {
        return (
            <NextLink href={callToAction.url} passHref prefetch={prefetch} legacyBehavior>
                <Button
                    as="a"
                    ref={ref}
                    target={callToAction.target}
                    title={callToAction.title}
                    href={callToAction.url}
                    variant={callToAction.variation}
                    iconAlignment={callToAction.iconAlignment}
                    icon={callToAction.iconName}
                    shade={callToAction.shade}
                    size={callToAction.size}
                    iconSize={callToAction.iconSize}
                    onClick={(e) => {
                        trackLink(callToAction, itemHeadline);
                        onClick && onClick(e);
                    }}
                >
                    {callToAction.text}
                </Button>
            </NextLink>
        );
    }

    if (callToAction.sidepanelModule) {
        return (
            <>
                <Button
                    ref={ref}
                    target={callToAction.target}
                    title={callToAction.title}
                    variant={callToAction.variation}
                    iconAlignment={callToAction.iconAlignment}
                    icon={callToAction.iconName}
                    shade={callToAction.shade}
                    onClick={(e) => {
                        trackLink(callToAction, itemHeadline);
                        onClick && onClick(e);
                        callToAction.sidepanelModule && setShowSidePanel(true);
                    }}
                >
                    {callToAction.text}
                </Button>
                <SidepanelModule
                    callToActionText={callToAction.text}
                    sidepanelModule={callToAction.sidepanelModule}
                    showSidePanel={showSidePanel}
                    handleCloseSidePanel={handleCloseSidePanel}
                />
            </>
        );
    }
});
